body,
#root {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: fit-content;
  min-height: 100vh;
  display: flex;
  font-family: "Nunito-Regular", sans-serif;

  --fw-normal: 400;
  --fw-regular: var(--fw-normal);
  --fw-medium: 500;
  --fw-bold: 700;
  --fw-black: 900;
  --max-width: 1920px;
  --input-shadow: 4px;
  --avatar-shadow: 6px;
  --topbar-padding: 30px;
  --topbar-min-height: 32px;
  --space-s: 20px;
  --space-m: 40px;
  --space-l: 50px;
  --space-xl: var(--space-l);

  --border-color: #000;
  --form-error-color: #e64c25;
  --form-valid-color: #03a076;
  --shadow-thickness: 11px;
  --cream-color-000: #f5f3ef;
  --border-cream-color-000: #ebe7df;
  --cream-color-400: #f2eee8;
  --border-cream-color-400: #e9e2d8;
  --cream-color-900: #e7e1d8;
  --border-cream-color-900: #ddd5c8;
  --cream-color-800: #ece7e0;
  --border-cream-color-800: #e2dbd0;
  --cream-color-700: #efeae4;
  --border-cream-color-700: #e6ded4;
  --dark-color-900: #000;

  --color-200: #c0f6e0;
}

body {
  position: relative;
  background-size: cover;
  overflow-x: hidden;
}

header {
  padding: 0 10px !important;
}

main {
  background: #EFF2F7 !important;
}

.header .logo {
  width: 100px;
}

@font-face {
  font-family: "Museo-Regular";
  src: local("Museo-Regular"), url(./assets/fonts/Museo300-Regular.otf) format("opentype");
}

@font-face {
  font-family: "Museo-Bold";
  src: local("Museo-Bold"), url(./assets/fonts/Museo700-Regular.otf) format("opentype");
}

@font-face {
  font-family: "MuseoSans-Thin";
  src: local("MuseoSans-100"), url(./assets/fonts/MuseoSans-100.otf) format("opentype");
}

@font-face {
  font-family: "MuseoSans-Regular";
  src: local("MuseoSans-300"), url(./assets/fonts/MuseoSans-300.otf) format("opentype");
}

@font-face {
  font-family: "MuseoSans-SemiBold";
  src: local("MuseoSans-500"), url(./assets/fonts/MuseoSans_500.otf) format("opentype");
}

@font-face {
  font-family: "MuseoSans-Bold";
  src: local("MuseoSans-700"), url(./assets/fonts/MuseoSans-700.otf) format("opentype");
}

@font-face {
  font-family: "Nunito-Light";
  src: local("Nunito-Light"), url(./assets/fonts/Nunito-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Regular";
  src: local("Nunito-Regular"), url(./assets/fonts/Nunito-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-Bold";
  src: local("Nunito-Bold"), url(./assets/fonts/Nunito-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito-ExtraBold";
  src: local("Nunito-ExtraBold"), url(./assets/fonts/Nunito-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish-ExtraBold";
  src: local("Mulish-ExtraBold"), url(./assets/fonts/Mulish-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish-Bold";
  src: local("Mulish-Bold"), url(./assets/fonts/Mulish-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish-Light";
  src: local("Mulish-Light"), url(./assets/fonts/Mulish-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Mulish-SemiBold";
  src: local("Mulish-SemiBold"), url(./assets/fonts/Mulish-SemiBold.ttf) format("truetype");
}


.p-2 {
  padding: 16px;
}

.px-2 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.d-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.d-center {
  justify-content: center;
}

.m-auto {
  margin: auto;
}

.t-center {
  text-align: center;
}

.t-right {
  text-align: right;
}

.w-full {
  width: 100%;
}

.m-visible {
  display: none;
}

.d-visible {
  display: block;
}

.ant-layout-footer {
  padding: 0 !important;
}

@media only screen and (min-width: 300px) and (max-width: 900px){
  .m-visible {
    display: flex;
  }

  .d-visible {
    display: none !important;
  }
}
