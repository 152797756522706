.main-column,
.beam-modal {
    * {
        font-family: Nunito-Regular;
    }
    .ant-modal-header {
        padding: 30px 30px 0;
        border-radius: 10px;
        background: #EFF2F7;
    }
    .ant-modal-content {
        border-radius: 10px;
        background: #EFF2F7;

        .ant-modal-close {
            top: 10px;
            right: 8px;
            .ant-modal-close-x {
                color: #000000;
                font-size: 20px;
            }
            &:active, &:focus {
                outline: none;
            }
        }
    }
    .ant-modal-body {
        padding: 30px;
    }

    .ant-input {
        height: 50px;
        border-radius: 15px;
        line-height: 26px;
        font-size: 16px;
        padding: 12px;
        background: inherit;
        border: 2px solid #d0daea;

        &::placeholder {
            color: #000000;
        }
    }
    .ant-input-affix-wrapper {
        height: 50px;
        border-radius: 15px;
        line-height: 26px;
        font-size: 16px;
        padding: 0 12px;
        background: inherit;
        border: 2px solid #d0daea;

        input {
            height: auto;
        }
        .ant-input-suffix {
            font-size: 18px;
        }
        .ant-input-prefix {
            margin-right: 12px;
        }
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        height: 50px;
        border-radius: 15px;
        font-size: 16px;
        padding: 0 12px;
        background: inherit;
        border: 2px solid #d0daea;

        .ant-select-selection-placeholder,
        .ant-select-selection-item {
            color: #000000;
            line-height: 3;
        }
    }
    .ant-select-arrow {
        font-size: 1rem;
        color: #000000;
        right: 18px;
    }
    .ant-select-multiple .ant-select-selection-item {
        line-height: 2 !important;
        height: 30px !important;
    }
    .prefix-select-wrapper {
        .ant-select-selector {
            border: none !important;
            height: 46px !important;
            box-shadow: none !important;
        }
        .ant-select-arrow {
            right: 5px !important;
        }
    }
    .ant-modal-title, h3 {
        font-size: 24px;
        color: #000000;
        margin-bottom: 0;
    }
    h4 {
        font-size: 18px;
        color: #000000;
        margin-bottom: 0;
    }
    h5 {
        font-size: 16px;
        color: #000000;
        margin-bottom: 0;
    }
    p {
        font-size: 14px;
        margin-bottom: 0;
        color: #91A5C6;
        line-height: 1.4;
    }
    .person-item {
        align-items: center;
        background: #ffffff;
        padding: 15px;
        border-radius: 10px;
        margin-bottom: 10px;
        img {
            border-radius: 50%;
            width: 50px
        }
    }
    .beam-btn {
        height: 44px;
        border-radius: 10px;
        padding: 0 14px;
        font-size: 18px;
        line-height: 24px;
        border: none;
        box-shadow: -8px -8px 8px #FFFFFF, 5px 5px 8px #CBD9F0;
        background: #EFF2F7;
        color: #000000;
        outline: none;
        &.inbound {
            background: #EFF2F7;
            box-shadow: inset -7px -7px 10px rgba(255, 255, 255, 0.5), inset 7px 7px 10px rgba(176, 179, 195, 0.25);
        }

        &.round-btn {
            border-radius: 15px;
            height: 40px;
            width: 40px;
            padding: 0;
            img {
                max-width: 30px;
                max-height: 30px;
            }
        }

        span {
            font-family: MuseoSans-Regular;
        }

        &:hover {
            background: #EFF2F7;
            color: #000000;
        }

        &.blue-btn {
            background: #508AFA;
            color: #ffffff;

            &:hover {
                background: #508AFA;
                color: #ffffff;
            }
        }

        &.green-btn {
            background: #EFF2F7;
            color: #40CC52;

            &.bordered {
                border: 2px solid #40CC52;
            }

            &.cover {
                background: #40CC52;
                color: #ffffff;
            }

            &:hover {
                background: #40CC52;
                color: #ffffff;
            }
        }

        &.orange-btn {
            background: #EFF2F7;
            color: #FF5F25;

            &.bordered {
                border: 2px solid #FF5F25;
            }

            &.cover {
                color: #ffffff;
                background: #FF5F25;
            }

            &:hover {
                background: #FF5F25;
                color: #ffffff;
            }
        }

        &.yellow-btn {
            background: #EFF2F7;
            color: #FBAE16;

            &.bordered {
                border: 2px solid #FBAE16;
            }

            &.cover {
                color: #ffffff;
                background: #FBAE16;
            }

            &:hover {
                background: #FBAE16;
                color: #ffffff;
            }
        }
        &.gray-btn {
            background: #EFF2F7;
            color: #91A5C6;

            &.bordered {
                border: 2px solid #91A5C6;
            }

            &.cover {
                color: #ffffff;
                background: #91A5C6;
            }

            &:hover {
                background: #91A5C6;
                color: #ffffff;
            }
        }
    }
    .action-grp {
        button {
            height: 50px;
        }
    }
    .ant-modal-footer {
        display: none;
    }
    .tiles {
        border: 2px solid #d0daea;
        border-radius: 16px;
        padding: 20px;
    }
    hr {
        background: #C5D2E9;
        height: 1px;
        border-radius: 10px;
    }
    .geofence-panel {
        align-items: center;
        display: flex;
        border-radius: 15px;
        padding: 10px 12px 10px 18px;
        color: #000000;
        box-shadow: inset -7px -7px 10px rgba(255, 255, 255, 0.5), inset 7px 7px 10px rgba(176, 179, 195, 0.25);
    }
    .gps-report {
        font-size: 16px;
        line-height: 2;
        height: 50px;
        align-items: center;
        display: flex;
        border-radius: 15px;
        padding: 10px 12px 10px 18px;
        color: #000000;
        box-shadow: inset -7px -7px 10px rgba(255, 255, 255, 0.5), inset 7px 7px 10px rgba(176, 179, 195, 0.25);
        label {
            font-size: 10px;
            color: #FF5F25;
            font-family: Nunito-ExtraBold;
            border: 2px solid #FF5F25;
            border-radius: 50%;
            margin-bottom: 0;
            margin-right: 10px;
        }
        div {
            line-height: 1;
            font-size: 12px;
        }
    }
    .popups {
        &.m-col-9 {
            grid-template-columns: repeat(3, 0fr);
        }
        &.m-col-15 {
            grid-template-columns: repeat(5, 0fr);
        }
        display: grid !important;
        gap: 20px;

        .popup-item {
            text-align: center;
            button {
                width: 70px;
                height: 70px;
                padding: 10px;
                border-radius: 25px;
            }
            p {
                font-size: 16px;
                color: #000000;
                margin-top: 10px;
            }
        }
    }
    .ant-upload-select {
        width: 100%;
        text-align: center;
        border: 2px solid #d0daea;
        border-radius: 16px;
        div {
            padding: 110px 20px;
            font-size: 24px;
            color: #000000;
        }
    }
    .datetime-item {
        border: 2px solid #d0daea;
        border-radius: 15px;
        height: 50px;
        align-items: center;
        display: flex;
        padding: 0 12px;
        width: 100%;
        .ant-picker {
            border: none;
            line-height: 26px;
            font-size: 16px;
            background: #EFF2F7;
            width: 100%;
            padding-right: 0;
            padding-top: 6px;
            box-shadow: none;
            input {
                font-size: 16px;
                &::placeholder {
                    color: #000000;
                    font-size: 16px;
                }
            }
            .anticon {
                color: #000000;
            }
        }
    }
    .ant-checkbox-wrapper {
        font-size: 16px;
        color: #000000;
        box-shadow: none;

        &:hover, &:focus {
            .ant-checkbox-inner {
                border-color: #40CC52;
            }
        }

        .ant-checkbox + span {
            padding: 0 5px 0 12px;
            position: relative;
            top: -5px;
        }
        .ant-checkbox-inner {
            width: 30px;
            height: 30px;
            border-radius: 12px;
            border: 2px solid #C5D2E9;
        }
        .ant-checkbox-checked {
            .ant-checkbox-inner {
                border-color: #40CC52;
                background-color: #40CC52;
                &:after {
                    width: 7px;
                    height: 14px;
                }
            }
        }
    }
    .ant-switch {
        width: 60px;
        height: 30px;
        border: 2px solid #91A5C6;
        background: inherit;
        .ant-switch-handle {
            height: 22px;
            width: 22px;
            left: 4px;
            &:before {
                background-color: #91A5C6;
                border-radius: 50%;
            }
        }
        &.ant-switch-checked {
            background-color: #40CC52;
            border-color: #40CC52;
            .ant-switch-handle {
                left: calc(100% - 18px - 8px) !important;
                &:before {
                    background-color: #ffffff;
                }
            }
        }
    }
    .ant-radio-wrapper {
        background: #EFF2F7;
        box-shadow: -8px 0px 10px #FFFFFF, 8px 8px 10px #DFE5EE;
        border-radius: 15px;
        padding: 5.5px 9px;
        margin: auto 0px auto 9px;

        .ant-radio:hover .ant-radio-inner {
            border-color: #C5D2E9;
        }
        .ant-radio-inner {
            width: 22px;
            height: 22px;
            border-width: 4px;
            border-color: #C5D2E9;
        }
        .ant-radio-checked .ant-radio-inner::after {
            top: -4px;
            left: -4px;
            width: 22px;
            height: 22px;
            border-radius: 50%;
        }
        &.orange-radio {
            .ant-radio-inner {
                border-color: #FF5F25;
            }
            .ant-radio-checked .ant-radio-inner::after {
                background: #FF5F25;
            }
        }
        &.blue-radio {
            .ant-radio-inner {
                border-color: #508AFA;
            }
            .ant-radio-checked .ant-radio-inner::after {
                background: #508AFA;
            }
        }
        &.green-radio {
            .ant-radio-inner {
                border-color: #40CC52;
            }
            .ant-radio-checked .ant-radio-inner::after {
                background: #40CC52;
            }
        }
        &.gray-radio {
            .ant-radio-inner {
                border-color: #91A5C6;
            }
            .ant-radio-checked .ant-radio-inner::after {
                background: #91A5C6;
            }
        }
        &.yellow-radio {
            .ant-radio-inner {
                border-color: #FBAE16;
            }
            .ant-radio-checked .ant-radio-inner::after {
                background: #FBAE16;
            }
        }
        &.pink-radio {
            .ant-radio-inner {
                border-color: #463BBD;
            }
            .ant-radio-checked .ant-radio-inner::after {
                background: #463BBD;
            }
        }
    }
    .plain-radios {
        .ant-radio-wrapper {
            background: none;
            box-shadow: none;
            padding: 0;
            margin: 5px auto;
            align-items: self-end;
            margin-right: 6px;
            font-size: 16px;

            .ant-radio-inner {
                width: 30px;
                height: 30px;
                border-width: 2px;
                border-color: #C5D2E9;
                border-radius: 12px;
            }
            .ant-radio-checked .ant-radio-inner {
                border-color: #40CC52;
                &::after {
                    top: 3px;
                    left: 3px;
                    width: 20px;
                    height: 20px;
                    border-radius: 12px;
                }
            }
        }
    }
    .ant-collapse {
        background: inherit;
        width: 100%;
        background: #EFF2F7;
        align-items: center;
        display: flex;
        box-shadow: inset -7px -7px 10px rgba(255, 255, 255, 0.5), inset 7px 7px 10px rgba(176, 179, 195, 0.25);
        border-radius: 20px;
        .ant-collapse-item {
            width: 100%;
            border: none;
            .ant-collapse-header {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                font-size: 16px;
                color: #000000;
                &:before, &:after {
                    content: none;
                }
                .ant-collapse-arrow {
                    margin: auto 0;
                    font-size: 1rem;
                }
            }
        }
    }
    &.analytics-summary-modal {
        .icon-btn {
            width: 40px;
            height: 40px;
            padding: 0;
            .green-icon {
                path {
                    fill: #40CC52;
                }
            }
            .blue-icon {
                path {
                    fill: #508AFA;
                }
            }
            .red-icon {
                path {
                    fill: #FF5F25;
                }
            }
            .yellow-icon {
                path {
                    fill: #FBAE16;
                }
            }
            .purple-icon {
                path {
                    fill: purple;
                }
            }
            .gray-icon {
                path {
                    fill: #91A5C6;
                }
            }
        }
        .incident-information {
            list-style: none;
            border: 2px solid #C5D2E9;
            border-radius: 10px;
            padding: 0;
            li {
                padding: 6px 25px;
                &:first-child {
                    border-radius: 10px 10px 0 0;
                }
                &:last-child {
                    border-radius: 0 0 10px 10px;
                }
                &:not(:last-child) {
                    border-bottom: 2px solid #C5D2E9;
                }
                &:nth-child(odd) {
                    background: #ffffff;
                }

                p {
                    color: #000000;
                }
            }
        }
        .locations {
            background: #FFFFFF;
            border-radius: 15px;
            padding: 3px 12px;
            .location-item {
                padding: 10px 0;
                .icon-btn {
                    width: 50px;
                    height: 50px;
                    border-radius: 20px;
                }
            }
        }
        .beam-btn {
            height: 40px;
            color: #91A5C6;
        }
        .person-item {
            border: 2px solid #C5D2E9;
            background: inherit;
            padding: 8px;
            label {
                color: #fff;
                font-size: 12px;
                line-height: 16px;
                background: #C5D2E9;
                border-radius: 15px;
                margin-bottom: 0;
                padding: 3px 13px;

                &.green-icon {
                    background: #40CC52;
                }

                &.blue-icon {
                    background: #508AFA;

                }
                &.red-icon {
                    background: #FF5F25;

                }
                &.yellow-icon {
                    background: #FBAE16;

                }
                &.purple-icon {
                    background: purple;

                }
                &.gray-icon {
                    background: #91A5C6;
                }
            }

            .icon-btn {
                border-radius: 15px;
            }
        }
        .assets-block {
            background: #C5D2E9;
            border-radius: 15px;
            padding: 19px 21px;
        }
        .timeline {
            border: 2px solid #C5D2E9;
            border-radius: 12px;
            padding: 19px 21px;
            height: 205px;
            .main-bar {
                display: flex;
                justify-content:space-between;

                &:before {
                    content: ' ';
                    position: absolute;
                    width: 100%;
                    height: 6px;
                    background: #C5D2E9;
                    box-shadow: -8px 0px 10px #FFFFFF, 8px 8px 10px #DFE5EE;
                    border-radius: 15px;
                }

                .time-point {
                    position: relative;
                    &.mark-time:before {
                        content: ' ';
                        position: absolute;
                        width: 2px;
                        height: 15px;
                        background: #C5D2E9;
                        border-radius: 15px;
                    }
                    &.break {
                        &:before {
                            width: 38px;
                            height: 20px;
                            border-radius: 50%;
                            top: -7px;
                            left: -19px;
                            background: url('/images/ecllips-icon.svg');
                            background-position: center;
                            background-size: 38px 20px;
                            background-repeat: no-repeat;
                        }
                        &:after {
                            content: ' ';
                            position: absolute;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                            top: -3px;
                            left: -6px;
                            border: 2px solid #ffffff;
                        }
                        &.orange-icon:after {
                            background: #FF5F25;
                        }
                        &.purple-icon:after {
                            background: purple;
                        }
                        &.yellow-icon:after {
                            background: #FBAE16;
                        }
                        &.gray-icon:after {
                            background: #91A5C6;
                        }
                    }
                    h6 {
                        font-size: 10px;
                        color: #91A5C6;
                        margin-top: 22px;
                        position: absolute;
                        width: 45px;
                        left: -15px;
                    }
                    .icon-btn {
                        width: 70px;
                        height: 70px;
                        padding: 0;
                        border-radius: 25px;
                    }
                }

                .break-point {
                    position: absolute;
                    top: 50px;
                    left: -35px;
                    text-align: center;
                }
            }
        }
        .custom-table {
            border: 2px solid #C5D2E9;
            border-radius: 12px;
            .header {
                font-size: 14px;
                color: #606B83;
                padding: 13px 30px;
                background: #ffffff;
                border-radius: 12px 12px 0 0;
                border-bottom: 2px solid #C5D2E9;
            }
            .table-row {
                padding: 9px 30px;
                color: #000000;
                font-size: 12px;
                word-break: break-all;
                .ant-col {
                    padding-right: 5px;
                }
                .event-column {
                    display: flex;
                    align-items: center;
                    > div {
                        min-width: 36px;
                        text-align: center;
                        margin-right: 10px;
                    }
                }
                label {
                    color: #ffffff;
                    padding: 3px 13px;
                    line-height: 16px;
                    border-radius: 20px;
                    margin-bottom: 0;
                    text-align: center;
                    &.green-icon {
                        background: #40CC52;
                    }

                    &.blue-icon {
                        background: #508AFA;

                    }
                    &.red-icon {
                        background: #FF5F25;

                    }
                    &.yellow-icon {
                        background: #FBAE16;

                    }
                    &.purple-icon {
                        background: purple;

                    }
                    &.gray-icon {
                        background: #91A5C6;
                    }

                }
                &:not(:last-child) {
                    border-bottom: 2px solid #C5D2E9;
                }
            }
        }
    }
}

.ant-modal {
    top: 0!important;
    max-width: 100vw!important;
    padding-bottom: 0!important;
}

.beam-modal .ant-modal-content{
    border-radius: 0!important;
}

.cursor-pointer{
    cursor: pointer;
}
